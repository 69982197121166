/* imports and utilities like animations and mixins should be defined here */
@import '~@xpo-ltl/ngx-ltl-core/styles/variables';
@import '~@xpo-ltl/symphony-ensemble-ui/styles/overrides';

$app-actionBar-height: 40px;

.app {
  &[class*='--hasActionBar'] {
    margin-bottom: $app-actionBar-height + 1;

    .app-ActionBar {
      margin-bottom: -$app-actionBar-height;
    }
  }
}
